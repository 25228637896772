(function ($) {
  Drupal.behaviors.tabbedBlockV1 = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      var $template = $('.js-tabbed-block-v1', context);

      $template.each(function () {
        var $this = $(this);
        var $blockTabs = $('.js-tabbed-block__tab', $this);
        var $blockSelectbox = $('.js-tabbed-block__selectbox', $this);

        $blockTabs.once().on('click', function () {
          var $thisTab = $(this);
          var $parent = $thisTab.closest('.js-tabbed-block-v1');
          var idBlockActive = $(this).attr('aria-controls');
          var $tabHeader = $('.js-tabbed-block__header-pc', $parent);
          var $tabbedBlockWrapperActive = $('#' + idBlockActive, $parent);
          var $tabbedHeaderActive = $('#cta_title_' + idBlockActive, $parent);
          var $blockContents = $('.js-tabbed-block__wrapper', $parent);
          var $editorialCarousel = $('.js-editorial-grid-formatter__carousel', $blockContents);

          $blockTabs.attr('aria-expanded', 'false');
          $thisTab.attr('aria-expanded', 'true');
          $blockContents.attr('aria-hidden', 'true');
          $tabbedBlockWrapperActive.attr('aria-hidden', 'false');

          $tabHeader.attr('aria-hidden', 'true');
          $tabbedHeaderActive.attr('aria-hidden', 'false');

          if ($editorialCarousel) {
            $editorialCarousel.slick('refresh');
          }
        });

        $blockSelectbox.change(function () {
          var $thisOpt = $(this);
          var $index = $thisOpt.prop('selectedIndex');
          var $parent = $thisOpt.closest('.js-tabbed-block-v1');
          var $blockContents = $('.js-tabbed-block__wrapper', $parent);
          var $editorialCarousel = $('.js-editorial-grid-formatter__carousel', $blockContents);

          $blockContents.attr('aria-hidden', 'true');
          $blockContents.eq($index).attr('aria-hidden', 'false');
          if ($editorialCarousel) {
            $editorialCarousel.slick('refresh');
          }
        });
      });
    }
  };
})(jQuery);
